interface Date {
  addDays(days: number): Date;
  differenceInDays(date: Date);
  differenceInDaysWithoutWeekends(date: Date);
  toDateOnlyString();
  getDayDate(): Date;
}

Date.prototype.addDays = function (days: number) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.differenceInDays = function (date: Date) {
  return Math.round((date.getTime() - this.getTime()) / (1000 * 60 * 60 * 24));
};

Date.prototype.differenceInDaysWithoutWeekends = function (date: Date) {
  const days = Math.round((date.getTime() - this.getTime()) / (1000 * 60 * 60 * 24)); // total number of days
  const sundays = Math.floor((days + ((this.getDay() + 6) % 7)) / 7); // number of sundays
  const saturday = Math.floor((days + (this.getDay() % 7)) / 7); // number of saturdays
  return days - sundays - saturday;
};

Date.prototype.toDateOnlyString = function () {
  // Get the year, month, and day
  const year = this.getFullYear();
  const month = (this.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so add 1
  const day = this.getDate().toString().padStart(2, '0');

  // Create the date string in "yyyy-mm-dd" format
  return `${year}-${month}-${day}`;
};

Date.prototype.getDayDate = function () {
  return new Date(this.getFullYear(), this.getMonth(), this.getDate());
};
