interface Array<T> {
  first(): T;
  last(): T;
  sortAlphabetically<T>(): T[];
  sortAlphabeticallyBy<T, K extends keyof any>(selector: (item: T) => K): T[];
}

Array.prototype.first = function () {
  return this[0];
};

Array.prototype.last = function () {
  return this[this.length - 1];
};

Array.prototype.sortAlphabetically = function <T>() {
  return this.sort((a: T, b: T) => a.toString().localeCompare(b.toString()));
};

Array.prototype.sortAlphabeticallyBy = function <T, K extends keyof any>(prop: (item: T) => K): T[] {
  return this.sort((a: T, b: T) => prop(a).toString().localeCompare(prop(b).toString()));
};
